//
// Overlay
// --------------------------------------------------


// Image overlay

.img-overlay,
.img-gradient-overlay {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .25s ease-in-out;
  background-color: $gray-900;
  opacity: .5;
  z-index: 1;
}
.img-gradient-overlay {
  background: rgba($gray-900, .5);
  background: linear-gradient(180deg, rgba($gray-900, 0) 0%, rgba($gray-900, 0.1) 26.56%, rgba($gray-900, 0.28) 42.71%, rgba($gray-900, 0.48) 56.77%, rgba($gray-900, 0.68) 72.4%, rgba($gray-900, 0.85) 86.98%, rgba($gray-900, 0.94) 100%);
  opacity: 1;
}


// Content overlay

.content-overlay {
  position: relative;
  z-index: 5;
}
