//
// Offcanvas
// --------------------------------------------------


// Header

.bg-dark .offcanvas-header {
  background-color: rgba($white, .05);
}


// Offcanvas body (content holder)

.offcanvas-body,
.fixed-nav-body {
  display: block !important;
  height: 100%;

  // Custom scrollbar inside offcanvas
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bg;
    @include border-radius($scrollbar-width * .5);
  }
  & > .simplebar-track {
    display: block;
    background-color: transparent;
  }
  .simplebar-vertical { margin-right: .1875rem; }

  // Nav inside offcanvas
  > :not(.card) {

    .nav {
      flex-direction: column;
      margin: {
        right: -$offcanvas-padding-x;
        left: -$offcanvas-padding-x;
      }
      > li { margin-bottom: 0; }
    }
    &:not(.navbar-nav) .nav-link {
      padding: .3rem $offcanvas-padding-x;
      font-weight: normal;
      &.active {
        position: relative;
        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: .125rem;
          height: 100%;
          background-color: $nav-link-active-color;
          content: '';
        }
      }
    }
    .nav-light .nav-link.active::before {
      background-color: $nav-link-light-active-color;
    }
  }
}

.bg-dark .offcanvas-body {
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-light-bg;
  }
}

.offcanvas .card-flush {
  border: 0;
  > .card-body { padding: 0; }
}
@include media-breakpoint-down(sm) {
  .offcanvas-sm .card-flush {
    border: 0;
    > .card-body { padding: 0; }
  }
}
@include media-breakpoint-down(md) {
  .offcanvas-md .card-flush {
    border: 0;
    > .card-body { padding: 0; }
  }
}
@include media-breakpoint-down(lg) {
  .offcanvas-lg .card-flush {
    border: 0;
    > .card-body { padding: 0; }
  }
}
@include media-breakpoint-down(xl) {
  .offcanvas-xl .card-flush {
    border: 0;
    > .card-body { padding: 0; }
  }
}
@include media-breakpoint-down(xxl) {
  .offcanvas-xxl .card-flush {
    border: 0;
    > .card-body { padding: 0; }
  }
}


// Offcanvas enabled container (for layouts with fixed side navigation)

@include media-breakpoint-up(lg) {
  .offcanvas-enabled-start {
    padding-left: $offcanvas-horizontal-width + 1.5rem;
  }
  .offcanvas-enabled-end {
    padding: {
      right: $offcanvas-horizontal-width + 1.5rem;
      left: 0;
    }
  }
}
.offcanvas {
  width: 30rem !important;
}