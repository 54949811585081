ul.organisation-switcher {

    li {
        position: relative;

        @for $i from 0 through 10 {
            &.organisation-level-#{$i} {
                margin-left: #{($i * 30)}px;
            }
        }

        i {
            position: absolute;
            left: -30px;
        }

        button {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.organisation-table {
    tr {
        position: relative;
        @for $i from 0 through 10 {
            .organisation-level-#{$i} {
                padding-left: #{($i * 30)}px;
            }
        }
    }
        .org-name {
            @for $i from 0 through 10 {
                .indent-icon-#{$i} {
                    position: absolute;
                    left: #{($i - 1) * 30}px;
                }
            }
        }
    }   

    .organisation-options-dropdown-toggle {
        border: 0;
        padding-left: 3px;
        padding-right: 3px;
        &::after {
            margin-left: 0 !important;
            content: none;
          }
    }