@import url('https://unpkg.com/leaflet@1.9.3/dist/leaflet.css');

.full-screen-wrapper {
    height: 100vh;
    width: 100vw;
}

.font-smallest {
    font-size: 8px;
}
.font-smaller {
    font-size: 12px;
}
.font-normal {
    font-size: 16px;
}
.font-bigger {
    font-size: 20px;
}
.font-biggest {
    font-size: 24px;
}

.offcanvas {

    &.offcanvas-end {
        top: 70px;
        z-index: 1029;
    }
}

.range-slider {
    margin-top: 2rem;
}

.react-datepicker-wrapper {

    &:not(:last-child) {
        .form-control {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right-width: 0
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.projects-table {
    th, td {
        padding: 0.75rem 0 !important;
    }
}

.workflows-landing-page {
    .nav-tabs {
        .nav-link {
            color: grey !important;
            font-weight: bold;
        }
        .nav-link.active {
            color: #010118 !important;
            border-color: #010118 !important;
        }
    }
}

.api-licence-agreement-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        margin: 0;
    }
    h1, h2, h3 {
        font-size: 16px;
    }

    .agreement-list > li::marker {
        color: #1f1b2d;
        font-weight: 800;
    }
}

@import url(global/loading.scss);
@import url(global/organisaton-switcher.scss);
@import url(global/simplebar.scss);
@import url(global/stamps.scss);
@import url(global/tags.scss);
@import url(components/forms.scss);
@import url(components/map.scss);
@import url(components/report.scss);