.tags-list {

    margin-top: 5px;

    button.tag {
        border-radius: 15px;
        background-color: var(--bs-white);
        border-color: var(--bs-gray-600);
        color: var(--bs-gray-600);
        font-size: 12px;
        font-weight: normal;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 10px;

        &.active {
            background-color: var(--bs-accent);
            border-color: var(--bs-accent);
            color: white;
        }

        &.add {
            background-color: var(--bs-primary);
            color: white;
        }
    }
}