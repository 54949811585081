body:has(.sketch-wrapper) {
    overflow: hidden;
}

.sketch-wrapper {
    display: flex;
    height: calc(100vh - 70px);
    margin-top: 70px;
    width: 100%;

    .sketch-filters {
        flex: 0 0 25%;

        .accordion-collapse {
            h3 {
                font-size: 0.9125em;
            }
        }
    }

    .map-container {
        height: calc(100vh - 70px);
        width: 100%;
        position: relative;

        >.loading-wrapper {
            position: absolute;
            top: 0;
            z-index: 1029;
        }

        .marker-icon-point {
            background: none;
            line-height: 1;

            &.move {
                cursor: move;
            }

            &.nesw {
                cursor: nesw-resize;
            }

            &.nwse {
                cursor: nwse-resize;
            }

            &.ns {
                cursor: ns-resize;
            }

            &.ew {
                cursor: ew-resize;
            }

            img, svg {
                width: 100%;
            }
        }

        .marker-icon-north-arrow {
            img {
                width: 100%;
            }
        }

        .marker-icon-stamp {
            img {
                width: 100%;
            }
        }


        .marker-icon-text-overlay {
            width: auto;

            .text-overlay {
                background: #fff;
                padding: 5px;
                border: solid 1px #cdcdcd;
                position: absolute;
                text-wrap: avoid;

                &:not(.has-width) {
                    white-space: nowrap;
                }
            }
        }

        .leaflet-popup-content-wrapper {
            overflow: visible !important;
        }

        &.print {

            .leaflet-control-container {
                display: none;
            }
        }
    }
}

.download-map-package-list {
    >li {

        border: none;
        min-height: 40px;
        padding: 0;

        .loading-wrapper {
            flex: 0 0 32px;
            margin-right: 10px;

            .spinner-border {
                width: 24px !important;
                height: 24px !important;
            }
        }

        button {
            border: none !important;
            display: none;
            flex: 0 0 32px;
            height: 32px;
            margin-right: 10px;
            width: 32px;
            padding: 0;

            .fi-download {
                font-size: 21px;
                width: 32px;
                text-align: center;
            }
        }

        >.fi-check {
            display: none;
            font-size: 21px;
            width: 32px;
            text-align: center;
            margin-right: 10px;
        }

        p {
            margin: 0;
        }

        &.complete {
            .loading-wrapper {
                display: none !important;
            }

            button,
            >.fi-check {
                display: block;
            }
        }
    }
}

.leaflet-container {
    background-color: #fff !important;
}

.leaflet-popup-pane {
    z-index: 6000;
}

.data-popup {
    width: 450px;

    .data-popup-photos {
        display: flex;

        .data-popup-photo {
            flex: 0 0 33.33%;
            padding: 2px;

            a { 
                img {
                    object-fit: cover;
                    aspect-ratio: 1 / 1;
                }
            }
        }
    }
}

.mini-map-container {
    height: 150px;
    width: 250px;
    position: relative;

    .leaflet-control-attribution {
        display: none;
    }
}

.map-messages-container {
    position: absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    bottom: 50px;
    z-index: 1000;
    left: 0;
    right: 0;
}