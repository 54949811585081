//
// Progress
// --------------------------------------------------


// Light version

.progress.progress-light { background-color: $progress-light-bg; }


// NProgress plugin styles used for page transition progress animation

#nprogress {
  pointer-events: none;

  .bar {
    position: fixed;
    z-index: $zindex-fixed + 5;
    top: 0;
    left: 0;
    width: 100%;
    height: .1875rem;
    background: $primary;
  }
  
  // Fancy blur effect
  .peg {
    display: block;
    position: absolute;
    right: 0;
    width: 6.25rem;
    height: 100%;
    box-shadow: 0 0 .625rem $primary, 0 0 .3125rem $primary;
    opacity: 1;
  
    -webkit-transform: rotate(3deg) translate(0, -.25rem);
        -ms-transform: rotate(3deg) translate(0, -.25rem);
            transform: rotate(3deg) translate(0, -.25rem);
  }

  // Spinner
  .spinner {
    display: block;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    z-index: $zindex-fixed + 5;
    margin-left: -(1.25rem + .75rem * 2) / 2;
    padding: .75rem;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;
  }
  
  .spinner-icon {
    width: 1.25rem;
    height: 1.25rem;
    box-sizing: border-box;
  
    border: solid .125rem transparent;
    border-top-color: $primary;
    border-left-color: $primary;
    border-radius: 50%;
  
    -webkit-animation: nprogress-spinner 400ms linear infinite;
            animation: nprogress-spinner 400ms linear infinite;
  }
}

// Spinner on dark background
.bg-dark #nprogress .spinner {
  background-color: darken($dark, 5%);
}

// Spiiner animation
@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
