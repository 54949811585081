ul.sketch-picker {

    li {
        position: relative;

        i {
            position: absolute;
            left: -30px;
        }

        button {
            padding: 0;
            font-weight: normal;

            &.active {
                font-weight: 700;
            }
        }
    }
}

.file-upload-button {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.file-input label {
    display: block;
    position: relative;
    padding: 0.425rem 1rem;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    color: #02033B;
    border: 1px solid #02033B;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    margin-right: 1rem;
  }

  .file-input label:hover {
    background-color: #02033B;
    color: #ffffff;
  }

  .color-picker {
      position: relative;
      width: 120px;
      height: 100%;

      .color-display {
          position: absolute;
          width: 100px;
          margin-right: 1rem;
      }

      .null-display {
          height: 100%;
          border-radius: 0.5rem;
          border: 1px solid #dcd2d2;
          padding: 0.75rem;
          background-size: 100% 100%;

          &::before {
            position: absolute;
            content: "";
            width: 100px;
            left: 0;
            top: 50%;
            right: 0;
            border-top: 2px solid var(--bs-danger);
            transform: rotate(-20deg);
          }
      }
  }