.stamps-list {
    .stamps-list-item {
        img {
            max-height: 100px;
            max-width: 100px;
        }

        button.tag {
            cursor: default;
        }
    }
}