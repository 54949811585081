.loading-wrapper {
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center;

    &.blur{
        backdrop-filter: blur(10px);
        height: 100%;
    }

    &.opaque {
        background: white;
    }
}